@font-face {
    font-family: 'EduVICWANT';
    src: url('./EduVICWANT.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lobster';
    src: url('./Lobster.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'YesevaOne';
    src: url('./YesevaOne.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LoveYaLikeASister';
    src: url('./LoveYaLikeASister.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'WeddingGothicATF';
    src: url('../Wedding\ Gothic\ ATF\ Regular\ \(1\).ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
