 
@font-face {
    font-family: "Inter";
    src: url("./Font/Inter-Light.ttf") format("opentype");
    font-style: normal;
    font-weight: 300; /* Light */
    font-display: swap;
  }
  
  @font-face {
    font-family: "Inter";
    src: url("./Font/Inter-Medium.ttf") format("opentype");
    font-style: normal;
    font-weight: 500; /* Medium */
    font-display: swap;
  }
  
  @font-face {
    font-family: "BlairITC";
    src: url("./Font/BlairITC-Medium\ \(1\).ttf") format("opentype");
    font-style: normal;
    font-weight: 550; /* Medium */
    font-display: swap;
  }

  @font-face {
    font-family: "Inter";
    src: url("./Font/Inter-Regular.ttf") format("opentype");
    font-style: normal;
    font-weight: 400; /* Regular */
    font-display: swap;
  }
  @font-face {
    font-family: "Inter";
    src: url("./Font/Inter-SemiBold.ttf") format("opentype");
    font-style: normal;
    font-weight: 600; /* SemiBold */
    font-display: swap;
  }
  @font-face {
    font-family: "Wedding Gothic ATF";
    src: url("./Font/Wedding\ Gothic\ ATF\ Regular\ \(1\).ttf") format("opentype");
    font-style: normal;
    font-weight: 350; /* Regular */
    font-display: swap;
  }
  
  
 