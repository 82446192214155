@import url("./Assets/CSS/Font.css");


body {
  font-family: "Inter, BlairITC, Wedding Gothic ATF";
  background-color: #0F0F0F;
}
body::-webkit-scrollbar {
  width: 6px !important;
  background-color: #F5F5F5 !important;
  border-radius: 10px !important;
}

body::-webkit-scrollbar-thumb {
  background-color: #3f2813 !important; 
  border-radius: 10px !important;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #F5F5F5 !important;
  border-radius: 10px !important;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}

.vrLine {
  width: 1.5px;
  background-color: rgba(128, 128, 128, 0.595);
  height: 30px;
}

.fAcJspace {
  width: "100%" !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.stepContainer {
  position: relative;
}

.stepLabel {
  position: absolute;
  top: -30px;
  width: 150px;
  font-size: 12px;
  font-weight: 600;
  color: #26a541;
}
.stepLabelDate {
  position: absolute;
  bottom: -30px;
  font-size: 11px;
  margin-left: -19px;
  width: 150px;
  font-weight: 600;
  /* color: #26a541; */
}

.stepContainer {
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 50%;
  background: #e6e6e6;
  border: 2px solid #e6e6e6;
  background-color: transparent;
  display: inline-block;
  margin-right: 10px;
}

.stepContainer.accomplished {
  background-color: #26a541;
  border: 2px solid #26a541; /* Change to your desired color */
}
.MuiDrawer-paper {
  width: 240px;
}
/* .MuiBox-root{
  overflow-x: hidden;
} */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.customSnackbar {
  background-color: #4caf50 !important; /* Your desired background color */
  color: #FFFFFF !important; /* Text color (optional) */
}