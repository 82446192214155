.custom-slick-slider {
    border: 1px solid red;
}
.slick-list {
    display: flex;
    justify-content: center;
}
.slick-slide div .MuiBox-root{
    display: flex !important;
    flex-direction: row;
    justify-content: center;
}
.slick-track {
    display: flex;
    justify-content: center;
}
.single-event .slick-slide:nth-of-type(1),
.single-event .slick-slide:nth-of-type(2) {
    display: none;
}
.dots-slick li div {
    background-color: gray !important;
}
.dots-slick li.slick-active div {
    background-color: #FFF !important;
}

.league-box::-webkit-scrollbar {
  width: 3px !important;
  background-color: #f5f5f538 !important;
  border-radius: 10px !important;
}

.league-box::-webkit-scrollbar-thumb {
  background-color: #19191996 !important;
  border-radius: 10px !important;
}
.league-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color:#f5f5f52b !important;
  border-radius: 10px !important;
}
.slick-next:before {
    display: none;
}
.slick-prev:before {
    display: none;
}
.typographyMenus .MuiFormControlLabel-label {
    font-family: inherit;
    font-size:inherit;
    font-weight: inherit;
}